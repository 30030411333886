<template>
<div class="push-block" v-if="blc.link && blc.link.isOk">
  <marquee-text
      class="scrolling"
      :repeat="10"
      :duration="blc.title.length * 1"
  >
    <div class="t">{{blc.title}}</div>
  </marquee-text>
  <container-grid-size>

    <v-row justify="center">
      <v-col cols="12" sm="10">
        <media-rounded-container>
          <v-responsive :aspect-ratio="16/9" min-height="250">
            <ee09-picture
                v-if="blc.image"
                :image-field="blc.image"
            />
            <ee09-video
                :video-field="blc.video"
            />
            <div class="content pa-line-2">
              <v-spacer></v-spacer>
              <h2 class="titre h2">{{blc.title}}</h2>
              <h4 class="ss-titre txt-label">{{blc.subtitle}}</h4>
              <btn-blue-arrow
                  class="mt-10"
                  :link="blc.link"/>
              <v-spacer></v-spacer>
            </div>

          </v-responsive>
        </media-rounded-container>
      </v-col>
    </v-row>

  </container-grid-size>
</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import ContainerGridSize from "@/components/atoms/container-grid-size";
import MediaRoundedContainer from "@/components/atoms/media-rounded-container";
import Ee09Picture from "@/components/atoms/ee09-picture";
import MarqueeText from 'vue-marquee-text-component'
import BtnBlueArrow from "@/components/atoms/btn-blue-arrow";
import Ee09Video from "@/components/atoms/ee09-video";

export default {
  name: "push-block",
  components: {Ee09Video, BtnBlueArrow, Ee09Picture, MediaRoundedContainer, ContainerGridSize,MarqueeText},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {PushBlock}
     */
    blc(){
      return this.block;
    }
  }
}
</script>

<style lang="less">
.push-block{
  text-align: center;
  .titre{
    margin-bottom: 0.25em;
  }

  .media-rounded{
    position: relative;
    background-color: #222;
    overflow: hidden;
  }
  .ee09-picture,.ee09-video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: brightness(0.7);
  }
  .content{
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .titre,.ss-titre{
      text-shadow: 1px 1px 4px rgba(0,0,0,.2),1px 1px 1px rgba(0,0,0,.5);
    }
  }
  position: relative;
  .scrolling{

    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .t{
      font-size: 12vw;
      font-family: var(--font-normal);
      font-weight: var(--fw-titles);
      color: var(--color-blue);
      padding-right: 0.5em;
    }
  }
}
</style>