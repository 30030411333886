<template>
<div class="carousel-pages-block py-0">
  <gradient-blue :transparent="blc.transparent">
    <container-grid-size class="pt-line-3 pb-line-10">
      <titre-icon-bas-wrapper
          :linkItem="linkItem"
          :arrow="blc.arrow"
          :center="blc.center">
        <component :is="blc.seo" :class="blc.cssClass">
          {{ blc.text }}
        </component>
      </titre-icon-bas-wrapper>
    </container-grid-size>
  </gradient-blue>

  <div class="carousel mt-line-n8">
    <v-container
        v-if="blc.gridMode">
        <v-row justify="center">
          <v-col v-for="page of blc.pages.records"
                 :key="`col-${page.uid}`"
                 cols="12" sm="6" md="4" lg="4" xl="3"
                 class="slide slide-grid">
            <page-card-portrait
                :page="page"/>
          </v-col>
        </v-row>
    </v-container>
    <carousel-pages v-else :block="blc"/>

  </div>
</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import ContainerGridSize from "@/components/atoms/container-grid-size";
import GradientBlue from "@/components/atoms/gradient-blue";
import TitreIconBasWrapper from "@/components/molecules/titre-icon-bas-wrapper";
import TitreLienMixin from "@/Models/blocks/texte/TitreLienMixin";
import PageCardPortrait from "@/Models/blocks/links/carousel-pages/page-card-portrait";
import CarouselPages from "@/Models/blocks/links/carousel-pages/carousel-pages";
export default {
  name: "carousel-pages-block",
  components: {
    CarouselPages,
    PageCardPortrait,
    TitreIconBasWrapper,
    GradientBlue,
    ContainerGridSize,
  },
  mixins:[BlockMixin,TitreLienMixin],
  computed:{
    /**
     *
     * @return {CarouselPagesBlock}
     */
    blc(){
      return this.block;
    }
  }
}
</script>

<style lang="less">
@import "../../../../../node_modules/swiper/swiper-bundle.css";
@import "carousel-slide";
.carousel-pages-block{
  overflow-x: hidden;
  width: 100vw;
  .carousel{
    margin-bottom: 5vw;
    margin-left: 20px;
    margin-right: 20px;
    >*{
      overflow: visible;
    }

  }

  .slide{
    position: relative;
    &.slide-grid{
      margin-bottom: 20px;
    }
    .media-rounded{
      background-color: #eee;
      margin-left: 20px;
      margin-right: 20px;
      overflow: hidden;
    }
    >*{
      height: 100%;
      position: relative;
      .ee09-picture{
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
      }
      .content{
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        position: absolute;
        color: #fff;
      }
    }
  }
  .swiper-button-next,.swiper-button-prev{
    color: #EEE;
    //pointer-events: none;
    opacity: 0.5;
    transform: scale(0.8);
    transition: all 0.5s;
    cursor: default;
    text-shadow: 1px 1px 4px rgba(0,0,0,.5);
    &.active{
      text-shadow: 1px 1px 4px rgba(0,0,0,.5),1px 1px 1px rgba(0,0,0,.5);
      cursor: pointer;
      //pointer-events: auto;
      opacity: 1;
      transform: scale(1);
    }
    &:after{

    }
  }
}
</style>