<template>
  <v-progress-circular
      class="progress-circular-iffdec"
      rotate="-90"
      :value="tweenedValeur"
      size="200" width="20" color="#3F81E9">
      <div class="percent-text">
        {{animatedValeurString}}
      </div>
  </v-progress-circular>
</template>

<script>
import { gsap } from "gsap";
export default {
  name: "progress-circular-iffdec",
  props:["valeur"],
  data(){
    return{
      tweenedValeur:0
    }
  },
  mounted() {
    gsap.to(this.$data, { duration: 1.0, tweenedValeur: this.valeur });
  },
  computed: {
    animatedValeurString: function() {
      return this.tweenedValeur.toFixed(0);
    }
  },
  watch: {
    valeur: function(newValue) {
      gsap.to(this.$data, { duration: 1.0, tweenedValeur: newValue });
    }
  }

}
</script>

<style lang="less">
.progress-circular-iffdec{
  user-select: none;
  width: 100% !important;
  circle{
    &:nth-child(1){
      stroke-linecap: round;
      stroke: #ECECEC;
    }
    &:nth-child(2){
      stroke-linecap: round;
      stroke: url('#svg-blue-gradient');
      stroke-width: 3;
      transition: none;
    }
  }
  .percent-text{
    color: #000;
    position: relative;
    font-size: 80px;
    font-weight: var(--fw-titles);
    &:after{
      font-size: 20px;
      content: "%";
      font-weight: bold;
      position: absolute;
      width: 100%;
      text-align: center;
      left: 0;
      bottom: 0;
    }
  }

}
</style>