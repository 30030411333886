<template>
<div class="push-photos-block"
     v-if="blc.link.isOk"
     :class="{'is-left':blc.left}"
>

  <container-grid-size class="my-line-2 line" >

    <media-rounded-container class="main-content">
      <div class="content" :class="pageType">
        <v-spacer/>
        <h2 class="titre h2">{{blc.title}}</h2>
        <h3 class="ss-titre h4" v-if="subtitle">{{subtitle}}</h3>
        <p class="text txt-paragraph" v-if="text">{{text}}</p>
        <btn-blue-arrow :link="blc.link"/>
      </div>

      <div class="image im-wrap" >
        <ee09-picture v-if="image1" :image-field="image1"/>
        <div class="mask-blue" ref="im1"/>
      </div>
    </media-rounded-container>

    <media-rounded-container class="side" >
      <div class="im-wrap" >
        <ee09-picture v-if="image2" :image-field="image2"/>
        <div class="mask-blue" ref="im2"/>
      </div>
    </media-rounded-container>

  </container-grid-size>



</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import ContainerGridSize from "@/components/atoms/container-grid-size";
import MediaRoundedContainer from "@/components/atoms/media-rounded-container";
import Ee09Picture from "@/components/atoms/ee09-picture";
import { gsap } from "gsap";
import BtnBlueArrow from "@/components/atoms/btn-blue-arrow";
export default {
  name: "push-photos-block",
  components: {
    BtnBlueArrow,
    Ee09Picture,
    MediaRoundedContainer,
    ContainerGridSize
  },
  mixins:[BlockMixin],
  data(){
    return{
      imageIndex:0,
      tl:null,
    }
  },
  methods:{
    nextImages(){
      this.imageIndex++;
      if(this.imageIndex>=this.blc.imagesOk.length){
        this.imageIndex=0;
      }
    },
  },
  mounted() {
    let me=this;
    let tl = gsap.timeline({repeat: -1, repeatDelay: 0});
    me.tl=tl;
    let im1=this.$refs.im1;
    let im2=this.$refs.im2;

    tl.fromTo(this.blc.right?im1:im2,{duration:0.5,x:'0%'},{x:'100%'});
    tl.fromTo(this.blc.right?im2:im1,{duration:0.5,x:'0%'},{x:'100%'});
    tl.set(im1,{x:'-100%'});
    tl.set(im2,{x:'-100%'});
    tl.to(this.blc.right?im1:im2,{duration:0.5,x:'0%'},'+=2');
    tl.to(this.blc.right?im2:im1,{duration:0.5,x:'0%'},);
    tl.call(me.nextImages, null, ">");
  },
  beforeDestroy() {
    this.tl.kill();
  },
  computed:{
    /**
     * @return {string} Le type de page
     */
    pageType(){
      if(this.page){
        return this.page.type;
      }
      return "";
    },
    /**
     * @return {PortraitModel|PageModel|MetierModel|PostModel|FormationModel|null} La page
     */
    page(){
      if(this.blc.link.isOk){
        if(!this.blc.link.external){
          return this.blc.link.pageRecord.record;
        }
      }
      return null;
    },
    subtitle(){
      switch (this.pageType){
        case "portrait":
          return this.page.fonction;
        case "post":
        case "projet":
          return this.page.baseline;
      }
      return null;
    },
    text(){
      return this.blc.text;
    },
    /**
     *
     * @return {PushPhotosBlock}
     */
    blc(){
      return this.block;
    },
    /**
     *
     * @return {null|ImageField}
     */
    image1(){
      if(this.blc.imagesOk.length<= 0){
        return null;
      }
      let idx=this.imageIndex;
      if(idx>=this.blc.imagesOk.length){
        idx=0;
      }
      return this.blc.imagesOk[idx];
    },
    /**
     *
     * @return {null|ImageField}
     */
    image2(){
      if(this.blc.imagesOk.length<= 0){
        return null;
      }
      let idx=this.imageIndex+1;
      if(idx>=this.blc.imagesOk.length){
        idx=0;
      }
      return this.blc.imagesOk[idx];
    }
  }
}
</script>

<style lang="less">
.push-photos-block{
  overflow-x: hidden;
  .media-rounded{
    overflow: hidden;
  }
  .im-wrap{
    overflow: hidden;
    border-radius: 0 !important;
    *{
      border-radius: 0 !important;
    }
    .mask-blue{
      background-color: var(--color-blue);
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;

    }
  }

  //hauteur responsive
  --h:50vw;
  @bp:660px;
  @media(min-width: @screen-sm){
    --h:50vw;
  }
  @media(min-width: @screen-lg){
    --h:40vw;
  }
  @media(min-width: @screen-xl){
    --h:25vw;
  }

  //le container + side
  .line{
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    .side{
      @media(max-width: @bp){
        display: none;
      }
      position: absolute;
      top: 0;
      height: 100%;
      width: var(--h);
      left: calc(100% + var(--line-unit));
    }
  }
  &.is-left{
    .line{
      .side{
        right: calc(100% + var(--line-unit));
        left: auto;
      }
    }
    .content{
      order: 2;
    }
  }

  .main-content{
    position: relative;
    background-color: #FFF;
    display: flex !important;
    flex-direction: column;
    @media(min-width: @bp){
      flex-direction: row;
    }

    overflow: hidden;
    >*{
      width: 100%;
      @media(min-width: @bp){
        width: 50%;
      }
      flex-grow: 0;
      flex-shrink: 0;
      min-height: var(--h);
      position: relative;
    }
    .content{
      padding: calc(var(--line-unit)*2);
      @media(min-width: @screen-lg){
        padding: calc(var(--line-unit)*3);
      }
      @media(min-width: @screen-xl){
        //padding: calc(var(--line-unit)*4);
      }
      color: #000;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      .titre,.ss-titre,.text,.btn{
        margin-top: calc( var(--line-unit) * 0.5 ) !important;
      }
      .btn{
        margin-top: calc( var(--line-unit) * 2 ) !important;
      }
      .titre{
        color: var(--color-blue);
      }
      &.formation{
        .titre{
          color: #000;
        }
      }
    }
    >.image{
      .ee09-picture{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        *{
          border-radius: 0 !important;
        }
      }
    }

  }

}
</style>