<template>
  <v-btn :to="link.to"
         elevation="10"
         :href="link.toOrHref"
         :target="link.target"
         class="bg-gradient-blue txt-link btn  btn-blue-arrow"
         x-large
  >
    {{link.label}}
    <v-icon large right class="ml-5 mr-2">iffdec-droite</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "btn-blue-arrow",
  props:{
    link:{
      /**
       * Le link item associé
       */
      type:Object
    }
  }

}
</script>

<style lang="less">
.btn-blue-arrow{
  color: #fff !important;
  *{
    color: #fff !important;
  }
}
</style>