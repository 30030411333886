<template>
<div class="infographies-block">
  <container-grid-size>
    <h3 class="h3 text-center mb-line-1" v-if="blc.titre">{{blc.titre}}</h3>
    <!--icones-->

    <infographies-list
        class="mx-auto"
        style="max-width: 1000px;"
        :dividers="blc.infographieType!=='pourcentage'">
      <infographie v-for="(info,i) of blc.infos.items" :key="i">
        <template v-slot:media>
          <div v-if="blc.infographieType==='chiffre'"
               class="h3">
            {{info.chiffre}}
          </div>

          <progress-circular-iffdec
              v-else-if="blc.infographieType==='pourcentage'"
              :valeur="info.pourcentage"/>

          <img v-else-if="blc.infographieType==='image' && info.image"
               :src="info.image.resize(200,200)"
               alt="todo">
        </template>
        <div v-html="info.texte"/>
      </infographie>
    </infographies-list>
  </container-grid-size>

</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import ContainerGridSize from "@/components/atoms/container-grid-size";
import InfographiesList from "@/components/molecules/infographies-list";
import Infographie from "@/components/molecules/infographie";
import ProgressCircularIffdec from "@/components/atoms/progress-circular-iffdec";

export default {
  name: "infographies-block",
  components: {ProgressCircularIffdec, Infographie, InfographiesList, ContainerGridSize},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {InfographiesBlock}
     */
    blc(){
      return this.block;
    }
  }
}
</script>