<template>
<div class="objectif-block"
     v-intersect="defaultIntersect">
  <block-classic-container>
    <v-row class="py-3" style="border-bottom: 3px solid black;">
      <v-col cols="10"
             :class="{invp:isIntersecting}"
             class="mask up d-flex align-center justify-start">
        <div class="txt-paragraph" v-html="blc.text"/>
      </v-col>
      <v-col cols="2"
             :class="{invp:isIntersecting}"
             class="mask pop text-right d-flex align-center justify-end">
        <v-icon class="icon-size">iffdec-check</v-icon>
      </v-col>
    </v-row>
  </block-classic-container>
</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import BlockClassicContainer from "@/Models/blocks/block-classic-container";
import InVpMixin from "@/components/atoms/InVpMixin";

export default {
  name: "objectif-block",
  components: {BlockClassicContainer},
  mixins:[BlockMixin,InVpMixin],
  computed:{
    /**
     *
     * @return {ObjectifBlock}
     */
    blc(){
      return this.block
    }
  }
}
</script>
<style lang="less">
.objectif-block{
  //outline: 1px solid grey;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  p{
    //line-height: 1.1;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
</style>
