<template>
<div class="textes-accordeons-block">
  <block-classic-container>
    <v-row>
      <template v-for="i in 2">
      <v-col cols="12" md="6" :key="i">
        <h4 class="h4 mb-line-1">{{blc['titre'+i]}}<i v-html="'&nbsp;'"></i></h4>
        <v-expansion-panels
            tile flat accordion
            class="mb-6 acc">
          <v-expansion-panel
              v-for="item of blc['liste'+i].items"
              :key="item.uid"
              :disabled="item.disabled"
          >
            <v-expansion-panel-header>
              <h4 class="txt-link">{{ item.titre }}</h4>
              <template v-slot:actions>
                <v-icon color="#000">
                  iffdec-plus
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-html="item.texte"></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      </template>
    </v-row>
  </block-classic-container>
</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import BlockClassicContainer from "@/Models/blocks/block-classic-container";

export default {
  name: "textes-accordeons-block",
  components: {BlockClassicContainer},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {TextesAccordeonsBlock}
     */
    blc(){
      return this.block;
    }
  }
}
</script>
<style lang="less">
.textes-accordeons-block{
  .acc{
    .v-expansion-panel-header{
      padding-left: 0;
      padding-right: 0;
    }
    .v-expansion-panel{
      border-bottom: 3px solid #000;
    }
    .v-expansion-panel-content__wrap{
      padding-left: 0;
      padding-right: 0;
    }
    .v-expansion-panel--disabled{
      color: #000 !important;
    }
  }
}
</style>