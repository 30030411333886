<template>
<div class="photo-list-block ">
  <container-grid-size class="pb-line-2">
    <v-row justify="center">
      <v-col cols="10">
        <v-row class="list">
          <v-col cols="6"
                 sm="6"
                 class="img"
                 v-for="image of blc.images.items"
                 :key="image.uid"
          >
            <media-rounded-container>
              <v-responsive :aspect-ratio="330/400">
                <ee09-picture
                    xs="250"
                    sm="400"
                    md="600"
                    lg="600"
                    xl="600"
                    :image-field="image.image"
                />
              </v-responsive>
            </media-rounded-container>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </container-grid-size>
</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import MediaRoundedContainer from "@/components/atoms/media-rounded-container";
import Ee09Picture from "@/components/atoms/ee09-picture";
import ContainerGridSize from "@/components/atoms/container-grid-size";

export default {
  name: "photo-list-block",
  components: {ContainerGridSize, Ee09Picture, MediaRoundedContainer},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {PhotoListBlock}
     */
    blc(){
      return this.block;
    }
  }
}
</script>

<style lang="less">
.photo-list-block{
  .media-rounded{
    position: relative;
    overflow: hidden;
    .ee09-picture{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  @media(min-width: @screen-md){
    .row.list{
      margin-left: calc(var(--line-unit) * -1);
      margin-right: calc(var(--line-unit) * -1);
      .img{
        padding-left: calc(var(--line-unit) * 1);
        padding-right: calc(var(--line-unit) * 1);
      }
    }
  }

  .img{
    &:nth-child(even){
      padding-top: calc(var(--line-unit) * 3);
    }
  }

}
</style>