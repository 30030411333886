<template>
<div class="citation-block">
  <container-grid-size>
    <gradient-blue class="big px-5">
      <v-row class="py-line-2">
        <v-col
            cols="12"
            sm="2"
            lg="3"
        >
          <v-icon dark x-large>mdi-format-quote-open</v-icon>
        </v-col>
        <v-col
            cols="12"
            sm="8"
            lg="6"
        >
          <div class="quote txt-quote" v-html="blc.text"/>
        </v-col>
        <v-col
            cols="12"
            sm="2"
            lg="3"
            class="d-flex flex-column justify-end">
          <v-icon dark x-large>mdi-format-quote-close</v-icon>
        </v-col>
      </v-row>
    </gradient-blue>
  </container-grid-size>

</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import ContainerGridSize from "@/components/atoms/container-grid-size";
import GradientBlue from "@/components/atoms/gradient-blue";

export default {
  name: "citation-block",
  components: {GradientBlue, ContainerGridSize},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {MetiersBlock}
     */
    blc(){
      return this.block;
    }
  }
}
</script>

<style lang="less">
.citation-block{
  color: #fff;
  text-align: center;
  .col{
    //outline: 1px solid red;
  }
}
</style>