<template>
<div class="iframe-block">
  <container-grid-size>
    <gradient-blue class="big">
    <v-row v-intersect="intersectLarge">
      <v-col sm="10" offset-sm="1" >
        <media-rounded-container>
          <v-responsive :aspect-ratio="16/9" class="elevation-24">
              <iframe
                  v-if="iframeBlock.iframeSrc && isIntersecting"
                  width="100%" height="100%"
                  :src="iframeBlock.iframeSrc"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
              />

          </v-responsive>
        </media-rounded-container>
      </v-col>
    </v-row>
    </gradient-blue>
  </container-grid-size>

</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import GradientBlue from "@/components/atoms/gradient-blue";
import ContainerGridSize from "@/components/atoms/container-grid-size";
import InVpMixin from "@/components/atoms/InVpMixin";
import MediaRoundedContainer from "@/components/atoms/media-rounded-container";

export default {
  name: "iframe-block",
  components: {MediaRoundedContainer, ContainerGridSize, GradientBlue},
  mixins:[BlockMixin,InVpMixin],
  computed:{
    /**
     *
     * @return {IframeBlock}
     */
    iframeBlock(){
      return this.block;
    }
  }
}
</script>

<style lang="less">
.iframe-block{
  .gradient-blue{
    padding-left: var(--line-unit);
    padding-right: var(--line-unit);
  }

  .gradient-blue{
    margin-bottom: calc(var(--line-unit)*5);
  }
  .media-rounded{
    overflow: hidden;
    background-color: #222;
    margin-top: calc(var(--line-unit)*1);
    margin-bottom: calc(var(--line-unit)*-3);
  }
  iframe{
    margin: 0;
    top: 0;
    left: 0;
    display: block;
    position: absolute;
    width: 100% !important;
    height: 100% !important;
  }
  @media(min-width: @screen-sm){
    .gradient-blue{
      padding-left: 0;
      padding-right: 0;
    }
    .media-rounded{
      margin-top: calc(var(--line-unit)*3);
    }
  }


}
</style>