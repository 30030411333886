<template>
<div class="block-formation-cycle">
  <div v-if="blc.isAnchor" :id="blc.uid"></div>
  <block-classic-container>
    <titre-icon-bas-wrapper>
      <component :is="blc.seo" class="h2">
        {{ blc.text }}
      </component>
    </titre-icon-bas-wrapper>
    <div class="mt-line-1 d-flex">
        <ico-txt-txt
            class="first"
            inline
            :text="blc.duree"
            icon="iffdec-rythme"
        />
        <ico-txt-txt
            inline
            :text="blc.rythme"
            icon="iffdec-duree"
        />
    </div>

  </block-classic-container>
</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import BlockClassicContainer from "@/Models/blocks/block-classic-container";
import IcoTxtTxtList from "@/components/molecules/infographies-list";
import IcoTxtTxt from "@/components/atoms/ico-txt-txt";
import TitreIconBasWrapper from "@/components/molecules/titre-icon-bas-wrapper";

export default {
  name: "formation-cycle-block",
  components: {TitreIconBasWrapper, IcoTxtTxt, IcoTxtTxtList, BlockClassicContainer},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {FormationCycleBlock}
     */
    blc(){
      return this.block
    }
  }
}
</script>
<style lang="less">
.block-formation-cycle{
  .first{
    margin-right: 2vw;
    min-width: 8vw;
  }
}
</style>
